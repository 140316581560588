import { checkMobileScreen } from '@/store/modules/ScreenWidth';

const EMPTY_TEXT = checkMobileScreen()
	? '入力内容に沿って Co-mode AI から\n画像が提案されます。'
	: '入力内容に沿って Co-mode AI から画像が提案されます。';

const PLACE_HOLDER = 'イメージに沿った単語や文章を入力してください。';
const PREDICT_BUTTON = '提案を見る';
const SCREEN_NAME = 'Co-mode AI';

const NO_INTERNET = 'Co-mode AIはインターネット接続環境でしかご利用いただけません。インターネット接続をご確認ください'
const BILLING_HARD_LIMIT_REACHED = 'Co-mode AIでエラーが発生しました。 Co-mode事務局までお問い合わせください'
const NSFW_TEXT = 'Co-mode AIでエラーが発生しました。Co-mode事務局までお問い合わせください'
const GENERAL_ERROR = '再度お試しいただくか、それでも解決しなかった場合は、Co-mode事務局までお問い合わせください'

export default {
	EMPTY_TEXT,
	PLACE_HOLDER,
	PREDICT_BUTTON,
	SCREEN_NAME,
	NO_INTERNET,
	BILLING_HARD_LIMIT_REACHED,
	NSFW_TEXT,
	GENERAL_ERROR,
};
