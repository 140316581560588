<template>
	<div class="skeletonLoadingImage">
		<img
			class="mainImage"
			:class="{
				'mainImage--contain': imageContain,
				'mainImage--cover': imageCover,
			}"
			:src="imageSrcBase64"
			alt=""
			@click="onImageClick"
			@load="onImageLoaded"
			@error="onImageLoadError"
		/>
		<ProcessingSpinner
			class="skeletonSpinner"
			v-if="imageSrc && isShowSkeletonLoading"
		/>
	</div>
</template>

<script>
import ProcessingSpinner from '../Spinner/Processing/Processing.vue';
export default {
	name: 'SkeletonLoadingImage',
	components: { ProcessingSpinner },
	props: {
		imageSrc: String,
		imageContain: Boolean,
		imageCover: Boolean,
	},
	data() {
		return {
			isShowSkeletonLoading: true,
			imageSrcBase64: '',
		};
	},

	watch: {
		imageSrc: {
			async handler(imageSrc) {
				if (imageSrc && imageSrc.includes('.svg')) {
					let imageSrcBase64 = await this._toDataURL(imageSrc);

					this.imageSrcBase64 = imageSrcBase64.replace(
						'binary/octet-stream',
						'image/svg+xml'
					);
				} else this.imageSrcBase64 = imageSrc;
			},
			immediate: true,
		},
	},

	methods: {
		onImageLoaded() {

			this.isShowSkeletonLoading = false;
			this.$emit('on-image-loaded');
		},

		onImageLoadError() {
			this.$emit('on-image-load-error');
		},

		onImageClick() {
			this.$emit('on-image-click');
		},

		async _toDataURL(url) {
			return new Promise((resolve, reject) => {
				var xhr = new XMLHttpRequest();
				xhr.onload = function () {
					var reader = new FileReader();
					reader.onloadend = function () {
						resolve(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.onerror = () => {
					reject({
						status: this.status,
						statusText: xhr.statusText,
					});
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			});
		},

		getBase64Image(url) {
			const img = new Image();
			img.setAttribute('crossOrigin', 'anonymous');
			img.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0);
				const dataURL = canvas.toDataURL('image/png');
				this.imageSrcBase64 = dataURL;
			};
			img.src = url;
		},
	},
};
</script>

<style src="./SkeletonLoadingImage.scss" lang="scss" scoped></style>
