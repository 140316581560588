import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';
import TextArea from '@/components/TextArea/TextArea.vue';

export default {
	name: 'ImagePreviewModal',
	components: { NotificationModal, SkeletonLoadingImage, TextArea },
	props: {
		image: {
			type: Object,
		},
		imgPreviewModalId: {
			type: String,
		},
		saveImgConfirmModalId: {
			type: String,
		},
		imageArray: {
			type: Array,
		},
		saveWhenInput: {
			type: Boolean,
			default: false,
		},
		isShowImageLink: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			comment: this.image['comment'] || '',
			selectiveContents: '内容を「コンセプトデザイン」に反映させます',
		};
	},

	watch: {
		'image.comment': function (newData) {
			this.comment = newData;
		},
	},
	methods: {
		onOpenConfirmModal() {
			this.$bvModal.hide(`${this.imgPreviewModalId}`);
			this.$bvModal.show(`${this.saveImgConfirmModalId}`);
		},

		onSaveImage() {
			const imageInfo = {
				id: this.image['id'],
				comment: this.comment,
				url: this.image['url'],
			};
			this.$emit('on-save', imageInfo);
			// this.comment = '';
			this.$bvModal.hide(`${this.imgPreviewModalId}`);
		},

		onCancel() {
			this.comment = this.image['comment'];
			this.$bvModal.hide(`${this.imgPreviewModalId}`);
		},
		onInput() {
			if (!this.saveWhenInput) return;
			const imageInfo = {
				id: this.image['id'],
				comment: this.comment,
				url: this.image['url'],
			};
			console.log('oninput', { imageInfo });
			// this.$emit('on-save', imageInfo);
		},
		clearComment() {
			this.comment = '';
		},

		onInputRemark({ comment }) {
			console.log({ comment });
			this.comment = comment;
		},
	},
};
