const { Configuration, OpenAIApi } = require('openai');
const configuration = new Configuration({
	apiKey: process.env.VUE_APP_BASE_OPENAI_KEY,
});
const openAIApi = new OpenAIApi(configuration);

let OpenAIService = {
	generateImg(prompt, numberImg) {
		return openAIApi.createImage({
			prompt: prompt,
			n: numberImg,
			size: '512x512',
			response_format: 'b64_json',
		});
	},
	generateTextMatome(prompt) {
		return openAIApi.createChatCompletion({
			model: 'gpt-3.5-turbo',
			messages: [
				{ role: 'system', content: '私はChatGPT、大きな言語モデルです。' },
				{
					role: 'user',
					content:
						prompt +
						' 上記の言葉からロゴの説明を 500 文字で書きます。日本語で返信してください',
				},
			],
			max_tokens: 2000,
		});
	},
	generateTextComodeAI(prompt) {
		return openAIApi.createChatCompletion({
			model: 'gpt-3.5-turbo',
			messages: [
				{ role: 'system', content: '私はChatGPT、大きな言語モデルです。' },
				{
					role: 'user',
					content:
						prompt +
						// ' 上記の言葉からロゴの説明を 100 文字で書きます。日本語で返信してください',
						' 上記の内容でロゴを作成したいので、100文字で要約してください。日本語で返信してください',
				},
			],
			max_tokens: 2000,
		});
	},
};

export default OpenAIService;
