import { checkMobileScreen } from '@/store/modules/ScreenWidth';

const DECISION_SCREEN_ID = 2;
const MAX_ITEM = 10;
const TEXT_AREA_MIN_HEIGHT = 48;

const SELECTIVE_CONTENTS = {
	creator: checkMobileScreen()
		? '「 詳細決め 」 確定依頼をクライアントに\n通知します'
		: '「 詳細決め 」 確定依頼をクライアントに通知します',
	client: '「 詳細決め 」を確定しますか？',
};
const PLAIN_NOTI_CONTENTS =
	' 「 クリエイティブ 」に進みます。\n しばらくお待ちください';
const CONFIRM_DELETE_CONTENTS = '削除してもよろしいですか';

const OPEN_MENU_NOTI_CONTENTS = '詳細決めフォーマットを選ぶ';

const DESIGN_TASTES = [
	{ id: 0, label: 'フォーマル', value: 0 },
	{ id: 1, label: '責任感', value: 0 },
	{ id: 2, label: 'しっかり', value: 0 },
	{ id: 3, label: '礼 節', value: 0 },
	{ id: 4, label: 'カジュアル', value: 0 },
	{ id: 5, label: '素 敵', value: 0 },
	{ id: 6, label: 'ゆっくり', value: 0 },
	{ id: 7, label: '愛 情', value: 0 },
	{ id: 8, label: '堅 い', value: 0 },
	{ id: 9, label: '格好良く', value: 0 },
	{ id: 10, label: '楽しく', value: 0 },
	{ id: 11, label: '庶 民', value: 0 },
	{ id: 12, label: '柔らかい', value: 0 },
	{ id: 13, label: 'お洒落', value: 0 },
	{ id: 14, label: 'きっちり', value: 0 },
	{ id: 15, label: '品の良さ', value: 0 },
];

const COLOR_PALETTES = [
	{
		id: 0,
		label: '赤配色',
		value: 0,
		description: '活動的／活気／情熱／勇敢\n積極的／挑戦／外交的',
	},
	{
		id: 1,
		label: '青配色',
		value: 0,
		description: '清潔／誠実／知的／静寂\n清涼感／男性的／ビジネス',
	},
	{
		id: 2,
		label: '緑配色',
		value: 0,
		description: '自然／穏やか／健康／休息\n安全／安心／信頼／平和',
	},
	{
		id: 3,
		label: '黄配色',
		value: 0,
		description: '明るさ／元気／軽快／協力\n好奇心／活動的／希望',
	},
	{
		id: 4,
		label: 'クール',
		value: 0,
		description: 'スマート／スタイリッシュ\nシンプル／都会的／知的',
	},
	{
		id: 5,
		label: 'ナチュラル',
		value: 0,
		description: '素朴／植物／自然／動物\n大地／リラックス ',
	},
	{
		id: 6,
		label: 'エレガント',
		value: 0,
		description: '上品／華やか／ゴージャス\n高貴／豪華／優雅／華麗',
	},
	{
		id: 7,
		label: 'キュート',
		value: 0,
		description: '可愛らしい／ピュア／甘い\n明るい／ガーリー／子ども',
	},
	{
		id: 8,
		label: '優しさ',
		value: 0,
		description: '親しみ／柔らかさ／温厚\n安心感／安らぎ／子ども',
	},
	{
		id: 9,
		label: 'レトロ',
		value: 0,
		description: '古い／歴史／和／落ち着き\n安定／質素／堅実／ぬくもり',
	},
	{
		id: 10,
		label: 'さわやか',
		value: 0,
		description: 'クリーン／清潔／清涼感\nフレッシュ／無垢／澄んだ',
	},
	{
		id: 11,
		label: 'カラフル',
		value: 0,
		description: '子ども／ポップ／好奇心／活発\n元気／賑やか／アニメ',
	},
	{
		id: 12,
		label: '淡い配色',
		value: 0,
		description: '落ち着き／大人／知性\n重厚／艶／洗練',
	},
	{
		id: 13,
		label: 'エコロジー色',
		value: 0,
		description: '健康的／涼しげ／自然／エコ\n新鮮／安らぎ／安全／信頼',
	},
	{
		id: 14,
		label: '高級感',
		value: 0,
		description: 'シック／ブランド／プロ／上品\n大人／気品／ビジネス／重厚',
	},
	{
		id: 15,
		label: '暖色系',
		value: 0,
		description: '暖かさ／活動的／活発\n生命力／賑やか／陽気',
	},
	{
		id: 16,
		label: '女性配色',
		value: 0,
		description: '可愛らしい／若さ／ポップ\n清楚／優しい／温厚',
	},
	{
		id: 17,
		label: '白黒',
		value: 0,
		description: 'シャープ／近代的／メリハリ\nスタイリッシュ／洗練',
	},
];

const FONTS = [
	{ id: 0, label: 'ゴシック', value: 0 },
	{ id: 1, label: '細ゴシック', value: 0 },
	{ id: 2, label: '太ゴシック', value: 0 },
	{ id: 3, label: '明朝', value: 0 },
	{ id: 4, label: '細明朝', value: 0 },
	{ id: 5, label: '太明朝', value: 0 },
	{ id: 6, label: '楷書体', value: 0 },
	{ id: 7, label: '細丸ゴシック', value: 0 },
	{ id: 8, label: '太丸ゴシック', value: 0 },
	{ id: 9, label: '行書', value: 0 },
	{ id: 10, label: '勘亭流 ', value: 0 },
	{ id: 11, label: 'POP', value: 0 },
];

const LOGO_FORMATS = [
	{ id: 0, label: '視認性優先', value: 0 },
	{ id: 1, label: '言語性優先', value: 0 },
	{ id: 2, label: 'シンボルマーク', value: 0 },
	{ id: 3, label: 'ロゴタイプ ', value: 0 },
	{ id: 4, label: '一体型', value: 0 },
];

const KEYWORD_TITLES = [
	{ key: 'designTastes', value: 'デザインテイスト' },
	{ key: 'colorPalettes', value: '配色イメージ' },
	{ key: 'fonts', value: '書体イメージ' },
	{ key: 'logoFormats', value: 'ロゴの形態' },
];

const PREDICTION_STATUS = {
	init: 'init',
	processing: 'processing',
	repredict: 'repredict',
	finished: 'finished',
};

export default {
	DECISION_SCREEN_ID,
	MAX_ITEM,
	SELECTIVE_CONTENTS,
	PLAIN_NOTI_CONTENTS,
	CONFIRM_DELETE_CONTENTS,
	OPEN_MENU_NOTI_CONTENTS,
	DESIGN_TASTES,
	COLOR_PALETTES,
	FONTS,
	LOGO_FORMATS,
	TEXT_AREA_MIN_HEIGHT,
	KEYWORD_TITLES,
	PREDICTION_STATUS,
};
