import ApiService from './api.service';

const serviceURL = 'translate';
const TranslateService = {
  get(text) {
    let body = {
      text: text,
    }
    return ApiService.post(`${serviceURL}/`, body);
  }
}

export default TranslateService;
