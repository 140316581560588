import SaveImageModal from '@/components/PreviewModal/ImagePreviewModal.vue';
export default {
	components: {
		SaveImageModal,
	},
	props: {
		imageUrl: {
			type: String,
			required: true,
		},
		previewModalId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			previewImage: '',
			saveImageModalId: 'save-image-modal',
			saveImage: {},
		};
	},
	methods: {
		onClose() {
			this.$bvModal.hide(this.previewModalId);
		},
		onOpenSaveModal() {
			this.saveImage = {
				url: this.imageUrl,
				comment: '',
			};
			this.$bvModal.show(this.saveImageModalId);
		},
		onSavePredictImage(imageInfo) {
			this.$emit('on-save-predict-image', imageInfo);
			this.$refs.saveImageModal.clearComment();
			this.$bvModal.hide(this.previewModalId);
		},
	},
};
