export default {
	name: 'TextArea',
	props: {
		index: {
			type: Number,
		},
		comment: {
			type: String,
		},
		disabled: {
			type: Boolean,
		},
		isEditAnswer: {
			type: Boolean,
		},
		isDelivery: {
			type: Boolean,
		},
		placeHolder: {
			type: String,
			default: 'コメント',
		},
		borderRed: {
			type: Boolean,
		},
		maxLength: {
			type: String,
		},
	},

	computed: {
		isBorderRed() {
			return this.borderRed && !this.comment;
		},
		letterLength() {
			const contentLength = this.remarksContent ? this.remarksContent.length : 0;

			return `${contentLength}/${this.maxLength}`;
		},
	},

	watch: {
		comment: {
			immediate: true,
			handler: function (comment) {
				this.remarksContent = comment;
			},
		},
	},

	data() {
		return {
			remarksContent: '',
		};
	},
	methods: {
		onInputContents() {
			let commentData = {
				index: this.index,
				comment: this.remarksContent,
			};
			this.$emit('on-input-remark', commentData);
			console.log({ commentData });
		},

		/**
		 * Revert preview data to empty
		 */
		_revertData() {
			this.remarksContent = undefined;
		},
	},
};
